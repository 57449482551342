import React, { useEffect, useState } from 'react'
import './UserTray.css'
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBListGroup, MDBListGroupItem, MDBNavbarItem, MDBRipple } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
import Avtor from '../../../modules/common/avtor/Avtor';

const UserTray = () => {
    const userData = useSelector(selectTokenData);
    const [userName, setUserName] = useState('');
    const [userType, setUserType] = useState('');
    const [profileKey, setProfileKey] = useState('');

    useEffect(() => {
        // console.log('userData', userData);
        if (userData) {
            setUserName((userData.FULLNAME && userData.FULLNAME !== 'unknown' && userData.FULLNAME !== '') ? userData.FULLNAME : getName(userData.EMAIL));
            setUserType(userData.USER_TYPE);
            setProfileKey(userData.USER_TYPE ? userData.OBJID : userData.OBJID);
        }

    }, [userData]);

    const getName = (email) => {
        if(!email) return '';
        let name = email? email.split('@')[0] : email;
        name = name.replace(/\.|_/g, ' ');
        if (name.length > appConfig.maxUserNameLength) {
            name = name.substring(0, appConfig.maxUserNameLength) + '...';
        }
        return name;
    }

    return (
        <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
            <MDBDropdown group className='shadow-0'>
                <MDBDropdownToggle color='link'>
                    <Avtor text={getName(userName)} />
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem >
                        <div className='user-try-container'>
                            <div className='user-try-x'>
                                <Avtor text={getName(userName)} />
                            </div>
                            <div className='user-info'>
                                <div className='user-name'>{getName(userName)}</div>
                                <div className='user-job'>{userType}</div>
                            </div>
                        </div>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider className='m-0' />
                    <MDBDropdownItem link href='/profile' disabled={true}>
                        <MDBIcon fas icon="user-alt" color='primary' className='mx-2' />
                        Profile
                    </MDBDropdownItem>
                    <MDBDropdownItem link href='/change-password' >
                        <MDBIcon fas icon="key" color='primary' className='mx-2' />
                        Change Password
                    </MDBDropdownItem>
                    <MDBDropdownItem link href='/logout'>
                        <MDBIcon fas icon="power-off" color='danger' className='mx-2' />
                        Logout
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </MDBNavbarItem>
    )
}

export default UserTray
