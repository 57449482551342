import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    props: null,
    bredcrumbs: [],
    isMobileMenu: false,
    showLeftnav: true,
    miniLeftNav: false,
    zipCode: null,
    isZipSearching: false,
    isLeftnavUpdated: false
};

export const appSlice = createSlice({
    name: '[app]',
    initialState,
    reducers: {
        setProps: (state, action) => {
            state.props = action.payload;
        },

        setBredcrumbs: (state, action) => {
            state.bredcrumbs = action.payload;
        },

        setShowLeftnav: (state, action) => {
            if (action.payload !== undefined) {
                state.showLeftnav = action.payload;
            }else{
                state.showLeftnav = !state.showLeftnav;
            }
        },

        setMiniLeftNav: (state, action) => {
            if (action.payload) {
                state.miniLeftNav = action.payload;
            }else{
                state.miniLeftNav = !state.miniLeftNav;
            }
        },

        setIsMobileMenu: (state, action) => {
            state.isMobileMenu = action.payload;
        },

        setZipCode: (state, action) => {
            state.zipCode = action.payload;
        },

        setIsZipSearching: (state, action) => {
            state.isZipSearching = action.payload;
        },
        setIsLeftnavUpdated: (state, action) => {
            state.isLeftnavUpdated = action.payload;
        }
    },
});

export const { setProps, setBredcrumbs, setShowLeftnav, setMiniLeftNav, setIsMobileMenu, setZipCode, setIsZipSearching, setIsLeftnavUpdated } = appSlice.actions;

export const selectProps = (state) => state.app.props;
export const selectBredcrumbs = (state) => state.app.bredcrumbs;
export const selectShowLeftnav = (state) => state.app.showLeftnav;
export const selectMiniLeftNav = (state) => state.app.miniLeftNav;
export const selectIsMobileMenu = (state) => state.app.isMobileMenu;
export const selectZipCode = (state) => state.app.zipCode;
export const selectIsZipSearching = (state) => state.app.isZipSearching;
export const selectIsLeftnavUpdated = (state) => state.app.isLeftnavUpdated;

export default appSlice.reducer;
