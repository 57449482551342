import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import './Auth.css'

import Login from '../../modules/auth/login/Login';
import WildComponent from '../../modules/common/wildComponent/WildComponent';
import CircularProgress from '../../modules/common/progress/Circular';
import appConfig from '../../configs/App.config';
import { useSelector } from 'react-redux';
import { selectToken, selectTokenData } from '../../redux/authSlice';
import ForgotPWD from '../../modules/auth/forgotPwd/Forgotpwd';

const AuthLayout = () => {
    return (
        <>
            {/* <WildComponent >
                <div className="loading">
                    <CircularProgress Width="100px" Height="100px" />
                </div>
            </WildComponent> */}
            <Routes>
                <Route path="/" exact element={<Navigate to="login" replace={true} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPWD />} />
                {/* <Route element={<Navigate to="verify" replace={true} />} /> */}
                <Route path="*" element={<Navigate to="/login" replace={true} />} />
            </Routes>
        </>
    )
}

export default AuthLayout
