import { createSlice } from '@reduxjs/toolkit';

const initState = {
    token: null,
    tokenData: null
};
const loadState = () => {
    try {
        const serializedState = localStorage.getItem('ejdh_admin_state');
        if (serializedState === null) {
            return initState;
        }
        const state = JSON.parse(serializedState);
        return (state.auth) ? state.auth : initState;
    } catch (e) {
        return initState;
    }
};


const initialState = loadState();

export const authSlice = createSlice({
    name: '[auth]',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload.token;
            state.tokenData = action.payload.tokenData;
        },
        logout: (state) => {
            state.token = null;
            state.tokenData = null;
        },
        updateUser: (state, action) => {
            state.tokenData = action.payload;
        }
    },
});

export const { login, logout, updateUser } = authSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectTokenData = (state) => state.auth.tokenData;
export const selectUserEmail = (state) => state.auth.tokenData?.emails?.[0];

export default authSlice.reducer;
