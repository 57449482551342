import { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBBadge,
    MDBInputGroup,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import './Comments.css'
import { useSelector } from 'react-redux';
import { selectToken } from '../../../redux/authSlice';
// import { withAITracking } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin } from '../../../services/appInsights';
import apis from '../../../services/apis';
import appConfig from '../../../configs/App.config';

const Comments = () => {
    const [cboTerm, setCboTerm] = useState("");
    const [commentsData, setCommentsData] = useState([]);
    const [selectedComment, setSelectedComment] = useState({});
    const [selectedCommentInd, setSelectedCommentInd] = useState({});
    const [gettingUserAccess, setGettingUserAccess] = useState(false);
    const [gettingUsers, setGettingUsers] = useState(false);
    const token$ = useSelector(selectToken);

    useEffect(() => {
        getComments();
    }, [token$])

    const getComments = () => {
        setGettingUsers(true);
        apis.setToken(token$).getComments(cboTerm).then((res) => {
            setCommentsData(res.length ? res : []);
        }).catch((error) => {
            console.log("error", error);
        }).finally(() => {
            setGettingUsers(false);
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        getComments();
    }

    const readComment = (id) => {
        setSelectedCommentInd(id);
        setSelectedComment(commentsData[id]);
        const data = commentsData;

        data[id].READ_STATUS = 1;
        setCommentsData([...data]);
        apis.setToken(token$).readComment(commentsData[id].OBJID, 1).then((res) => {
            console.log("res", res);
            // getComments();
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const getName = (name) => {
        if (name.length < 25) {
            return name;
        }
        return name.substring(0, 25) + '...';
    }

    const getDateTime = (date) => {
        if (date === null) {
            return '';
        }
        return new Date(date).toLocaleString();
    }

    const changeAllReadState = (state) => {
        apis.setToken(token$).makeAllReasUnread(state).then((res) => {
            getComments();
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const makeAsUnread = (e) => {
        apis.setToken(token$).readComment(selectedComment.OBJID, 0).then((res) => {
            // getComments();
            const data = commentsData;
            data[selectedCommentInd].READ_STATUS = 0;
            setCommentsData([...data]);
        }).catch((error) => {
            console.log("error", error);
        })
    }

    return (
        <>
            <div className='row clearfix px-1'>
                <div className='col-3'>
                    <div className='d-flex border-1 border-bottom'>
                        <h4 className='me-auto'>Comments</h4>
                        <span style={{
                            zoom: '0.8'
                        }}>
                            <MDBBtn color='primary' outline size='sm' title='Mark all read' className='me-1 z-n1' onClick={()=>{ changeAllReadState(1) }}>
                                <MDBIcon far icon="eye" className='' />
                                {/* Mark all read */}
                            </MDBBtn>
                            <MDBBtn color='primary' outline size='sm' title='Mark all Unread' className='me-1 z-n1' onClick={()=>{ changeAllReadState(0) }}>
                                <MDBIcon far icon="eye-slash" className='' />
                                {/* Mark all Unread */}
                            </MDBBtn>
                        </span>
                    </div>
                    {gettingUsers && <h3 className='nodata-placeholder'>
                        <MDBIcon fas icon="spinner" className='fa-spin' /> Loading
                    </h3>}
                    {(!gettingUsers && commentsData.length > 0) && <MDBListGroup light style={{
                        height: 'calc(100vh - 130px)',
                        overflowY: 'auto',
                    }}>
                        {commentsData.map((item, index) => {
                            return <MDBListGroupItem className="border-bottom border-1 px-3 py-1"
                                title={item.FULLNAME} key={item.ID} tag={"button"}
                                onClick={() => { readComment(index) }} active={item.ID === selectedComment.ID} action noBorders>
                                <MDBIcon fas icon="angle-double-right" className='' />
                                {item.READ_STATUS === 0 ? <strong className=''><em>{getName(item.SUBJECT)}</em></strong> : getName(item.SUBJECT)}
                            </MDBListGroupItem>
                        })}

                    </MDBListGroup>
                    }
                    {commentsData.length === 0 && !gettingUsers && <h3 className='nodata-placeholder' style={{
                        marginTop: 'calc(40vh - 50px)'
                    }}>No Comments found</h3>}
                </div>
                <div className='col-9 border-start border-3 border-secondary'>
                    {selectedComment.ID === undefined && <h3 className='nodata-placeholder'>Select a Comment to read</h3>}
                    {selectedComment.ID && <>
                        < div className='d-flex border-bottom border-3 border-secondary'>
                            <div className='me-auto'>
                                <div className='d-flex'>
                                    <h3 className='me-auto'>{selectedComment.SUBJECT}</h3>
                                    <h6 className='pt-1 ms-2'>
                                        {selectedComment.USER_STATUS === 'ACTIVE' && <MDBBadge pill color='success' className='me-auto'>{selectedComment.USER_STATUS}</MDBBadge>}
                                        {selectedComment.USER_STATUS !== 'ACTIVE' && <MDBBadge pill color='danger' className='me-auto'>{selectedComment.USER_STATUS}</MDBBadge>}
                                    </h6>
                                </div>
                            </div>
                            <div className='ms-auto'>
                                <MDBBtn color='primary' size='sm' className='me-1' disabled={gettingUserAccess} onClick={makeAsUnread}>
                                    <MDBIcon far icon="eye-slash" className='me-2' />
                                    Mark as Unread
                                </MDBBtn>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between' style={{
                            height: 'calc(100vh - 130px)',
                            overflowY: 'auto',
                        }}>
                            <MDBTable hover>
                                <MDBTableBody>
                                    {/* <tr>
                                        <th scope='row' className='py-2 w-30'>
                                            <strong>Commenter Name</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'>: {selectedComment.USER_TYPE}</td>
                                    </tr> */}
                                    <tr style={{
                                        height: '50px'
                                    }}>
                                        <th scope='row' className='py-2 w-25'>
                                            <strong>Commenter Email</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'>: {selectedComment.EMAIL ?
                                            <a target='new' href={`mailto:${selectedComment.EMAIL}`}>{selectedComment.EMAIL}</a> : ''
                                        }</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2' colSpan={2}>
                                            <strong>Description</strong>
                                            <div className='py-2'>{selectedComment.DESC}</div>
                                        </th>
                                        {/* <td className='pl-3 py-2 pl-4'>: {selectedComment.PHONE}</td> */}
                                    </tr>

                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </>}
                </div>
            </div >
        </>
    )
}

export default Comments;
