import React, { useEffect } from 'react'
import CircularProgress from '../../common/progress/Circular'
import { useDispatch, useSelector } from 'react-redux';
import { login, selectToken, selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
// import appConfig from '../../../configs/app.config';

const Logout = () => {
    const userData = useSelector(selectTokenData);
    const token$ = useSelector(selectToken);
    const dispatch = useDispatch();
    useEffect(() => {
        // localStorage.removeItem('ejdh_admin_state');
        dispatch(login({
            token: null,
            tokenData: []
        }));
    },[]);

    useEffect(() => {
        if (token$ === null) {
            window.location.replace("/");
        }
    }, [token$]);

    return (
        <div className="loading">
            <CircularProgress Width="100px" Height="100px" />
            <h5>
                Logging out...
            </h5>
        </div>
    )
}

export default Logout
