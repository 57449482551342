import React from 'react';
import './Avtor.css';
export default function Avtor({text}) {
    const avtorText = (text) => {
        let name = text.split(' ');
        let avTxt = '';
        if(name.length > 1){
            avTxt = `${name[0].charAt(0)}${name[1].charAt(0)}`;
        }else{
            avTxt = `${name[0].charAt(0)}`;
        }
        return avTxt.toUpperCase();
    }
  return (
    <>
        <span className='avtor'>
            {avtorText(text)}
        </span>
    </>
  );
}
