import React, { useEffect, useState } from 'react';
import './App.css';
import AppLayout from './layouts/app/appLayout/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, selectToken } from './redux/authSlice';
import CircularProgress from './modules/common/progress/Circular';
import AuthLayout from './layouts/auth/Auth.layout';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, appInsights } from './services/appInsights';
import appConfig from './configs/App.config';
import jwt_decode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import AppContainer from './layouts/appContainer/AppContainer';
import apis from './services/apis';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const authTtoken = useSelector(selectToken);
  // const keepMeLoggIn = useSelector(selectKeepMeLoggedIn);
  const [urlparts, setUrlParts] = useState(window.location.href.split('/'));
  const dispatch = useDispatch();
  useEffect(() => {
    if (authTtoken) {
      apis.setToken(authTtoken).checkLogin().then((res) => {
        console.log(res);
        if (res.isValid) {
          setIsAuthenticated(true);
          setIsLoading(false);
          // window.location.replace('/login');
        }else{
          dispatch(logout());
          setIsAuthenticated(true);
          setIsLoading(false);
        }
      }).catch((err) => {
        dispatch(logout());
        setIsAuthenticated(false);
        setIsLoading(false);
        // window.location.replace('/login');
      });
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
      // window.location.replace('/login');
    }

  }, []);

  const handleTabClosing = () => {
    // if (!keepMeLoggIn){
    //   localStorage.removeItem('ejdh_admin_state');
    // }
  }

  return (
    <>
      <div className="app-container">
        <ToastContainer />
        {
          (isLoading) ?
            <div className="loading">
              <CircularProgress Width="100px" Height="100px" />
            </div>
            :
            isAuthenticated ? <AppLayout /> : <AuthLayout />
        }
        {/* {(!isLoading) && <AppContainer />}
        {(!isLoading && isAuthenticated===true) && <AppLayout />}  */}
      </div>

    </>
  );
}

// export default withAITracking(reactPlugin, App);
export default App;
