import React, { useEffect, useState } from 'react';
import {
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
} from 'mdb-react-ui-kit';
import { useDispatch } from 'react-redux';
import apis from '../../../services/apis';

export default function ForgotPWD() {
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [gotoDashboard, setGotoDashboard] = useState(false);
    //const [ticker, setTicker] = useState(null);
    const [token, setToken] = useState(null);
    const [ticks, setTicks] = useState(0);
    const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [enableFpwd, setEnableFpwd] = useState(false);

    useEffect(() => {
        if (username) {
            // validate email
            var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (regex.test(username)) {
                setShowError(false);
                setEnableFpwd(true);
            } else {
                setErrMsg('Invalid email address');
                setShowError(true);
                setEnableFpwd(false);
            }
        } else {
            setShowError(false);
            setEnableFpwd(false);
        }
    }, [username]);

    const doSend = () => {
        if (!enableFpwd) return;
        // send reset link
        apis.sendResetLink(username)
            .then((res) => {
                if (res.code >= 400) {
                    setErrMsg(res.message);
                    setShowError(true);
                } else {
                    setIsSuccess(true);
                }
            }).catch((err) => {
                setErrMsg(err.message);
                setShowError(true);
            });
    };

    return (
        <>
            <div className='login-form'>
                <div className="login-form-container">
                    <MDBCard alignment='center'>
                        <MDBCardBody>
                            <MDBCardTitle>
                                <div className="login-form-header">
                                    <img src="/images/logo-ejdh.png" alt="mdb logo" />
                                    <h6 className='mt-4'>
                                        Forgot Password
                                    </h6>
                                </div>
                            </MDBCardTitle>
                            {!isSuccess && <>
                                <MDBCardText tag={"div"}>
                                    <div className="login-form-body">
                                        <div className='my-3'>
                                            <MDBInput
                                                label='Email'
                                                id='username'
                                                type='text'
                                                onChange={(e) => {
                                                    setUsername(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </MDBCardText>
                                <div className="my-3 d-grid gap-2 col-6 mx-auto">
                                    <MDBBtn onClick={doSend} disabled={!enableFpwd} >
                                        SEND RESET LINK
                                    </MDBBtn>
                                    <MDBBtn color='link' rippleColor='dark' onClick={(e) => {
                                        e.preventDefault();
                                        window.location.replace("/login");
                                    }}>
                                        Back to Login
                                    </MDBBtn>
                                </div>
                                {showError &&
                                    <div className="error-block">
                                        <p>{errMsg}</p>
                                    </div>}
                            </>}
                            {isSuccess &&
                                <div className="success-block">
                                    <p>
                                        A reset link has been sent to your email address.
                                    </p>
                                    <MDBBtn color='link' rippleColor='dark' onClick={(e) => {
                                        e.preventDefault();
                                        window.location.replace("/login");
                                    }}>
                                        Back to Login
                                    </MDBBtn>
                                </div>}
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
        </>
    );
}
