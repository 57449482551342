import React, { useEffect, useState } from 'react'
import {
    MDBIcon,
    MDBCollapse,
    MDBRipple,
    MDBAccordion,
    MDBAccordionItem,
    MDBListGroup,
    MDBListGroupItem,
    MDBNavbarBrand,
    MDBBtn,
    MDBNavbarToggler,
    MDBBtnGroup,
    MDBInputGroup,
    MDBInput
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLeftnavUpdated, selectMiniLeftNav, selectShowLeftnav, setIsLeftnavUpdated, setIsMobileMenu, setMiniLeftNav, setShowLeftnav, setZipCode } from '../../../redux/appSlice';
import { useNavigate } from 'react-router-dom';
import './LeftNav.css';
import { Link, NavLink } from 'react-router-dom';
import appRoutes from '../../../configs/Routes.config';
import { selectToken, selectTokenData, selectUserEmail } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';
import { toast } from 'react-toastify';

const LeftNav = (props) => {
    const dispatch = useDispatch();
    const showLeftNav = useSelector(selectShowLeftnav);
    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const [searchTerm, setSearchTerm] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [savedReports, setSavedReports] = useState(null);
    const userEmail$ = useSelector(selectUserEmail);
    const isLeftnavUpdated$ = useSelector(selectIsLeftnavUpdated);
    const token$ = useSelector(selectToken);
    const userData = useSelector(selectTokenData);
    const navigate = useNavigate();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        if (selectIsLeftnavUpdated) getSavedReports();
    }, []);
    useEffect(() => {
        if (isLeftnavUpdated$) {
            getSavedReports();
            dispatch(setIsLeftnavUpdated(false))
        }

        window.addEventListener("resize", updateDimensions);
        if (width <= 992) {
            dispatch(setShowLeftnav(false));
            dispatch(setIsMobileMenu(true));
        } else {
            dispatch(setShowLeftnav(true));
            dispatch(setIsMobileMenu(false));
        }
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isLeftnavUpdated$]);

    useEffect(() => {
        let openMnu = document.querySelector('.nested-menu.open');
        if (openMnu) {
            openMnu.style.height = isMiniLeftnav ? '66px' : '40px';
            openMnu.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            openMnu.children[0].children[1].style.transform = 'rotate(0deg)';
            openMnu.classList.remove('open');
        }
    }, [isMiniLeftnav]);

    const toggleShow = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
        }
        dispatch(setShowLeftnav());
    }

    const getSavedReports = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token$}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/reports/${userData.ID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status >= 400) {
                    toast.error('Faied to fetch saved reports', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    return;
                }
                setSavedReports([...result]);
            })
            .catch(error => console.log('error', error));
    }
    const toggleSubNav = (e) => {
        e.preventDefault();
        let isClosed = false;
        let pelm = e.target;
        while (pelm.tagName !== 'A') {
            pelm = pelm.parentElement;
        }
        if (pelm.tagName === 'A') {
            // pelm.children[1].style.transform = e.target.parentElement.classList.contains('open') ? 'rotate(0deg)' : 'rotate(90deg)';
            pelm = pelm.parentElement;
            isClosed = !pelm.classList.contains('open');
        }

        // if (!isClosed) {
        // close all open sub menu
        let openMnu = document.querySelector('.nested-menu.open');
        if (openMnu) {
            // openMnu = openMnu.parentElement;
            openMnu.style.height = isMiniLeftnav ? '66px' : '40px';
            openMnu.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            openMnu.children[0].children[1].style.transform = 'rotate(0deg)';
            openMnu.classList.remove('open');
        }
        // }

        if (isClosed) {
            pelm.classList.toggle('open');
            pelm.children[0].children[1].style.transition = 'all 0.3s ease-in-out';
            pelm.children[0].children[1].style.transform = 'rotate(90deg)';
            let h = pelm.children[1].offsetHeight;
            pelm.style.height = (h + (isMiniLeftnav ? 66 : 40)) + 'px';
        }
    }

    const searchOnchange = (e) => {
        setSearchTerm(e.target.value);
    }

    const doSearch = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
            return;
        }

        // dispatch(setZipCode(searchTerm));
        // navigate('/search/'+searchTerm);
        console.log(window.location)
        window.location.replace('/search/' + searchTerm);
        console.log(window.location)
    }

    const getLabel = (label) => {
        if (isMiniLeftnav && label.length > 10) {
            let parts = label.split(' ');
            label = parts.join('<br />');
        }
        return { __html: label };
    }

    return (
        <>
            <MDBCollapse tag="nav" className={`d-lg-block sidebar show ${isMiniLeftnav ? 'mini' : ''}`} style={{
                transform: showLeftNav ? "translateX(0)" : "translateX(-250px)",
                padding: '5px',
                paddingLeft: 0
            }}>
                <MDBNavbarToggler
                    type='button'
                    className={`bg-light text-primary ${showLeftNav ? 'close-nav' : ''} border-1 border-color-primary`}
                    aria-label='Toggle navigation'
                    onClick={toggleShow}>
                    <MDBIcon icon='times' fas className='text-danger' />
                </MDBNavbarToggler>
                <div className="position-sticky">
                    <MDBNavbarBrand href='#' className='border-bottom'>
                        <img
                            src='/images/logo-ejdh.png'
                            height='30'
                            alt=''
                            loading='lazy'
                        />

                    </MDBNavbarBrand>
                    <div className='menu-container'>
                        <div className='menu-header show-in-mobile border-bottom'>
                            <div className='m-2 my-3 search-box'>
                                <MDBInputGroup className=''>
                                    <input
                                        className={`txtbox ${isMiniLeftnav ? 'hide' : ''}`}
                                        placeholder="Search with zip code"
                                        value={searchTerm}
                                        onChange={searchOnchange}
                                        onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                                doSearch();
                                            }
                                        }}
                                        type='text' />
                                    <button
                                        className={`search-btn ${isMiniLeftnav ? 'mini' : ''}`}
                                        onClick={doSearch}
                                        type='button'>
                                        <MDBIcon fas icon="search" />
                                    </button>
                                </MDBInputGroup>
                            </div>
                        </div>
                        <div className='menu-body scrollbar scrollbar-default'>
                            <MDBListGroup flush="true" className="rounded-0" style={{
                                transition: 'all 0.3s ease-in-out'
                            }}>
                                <MDBRipple rippleTag='li'>
                                    <MDBListGroupItem tag='div' action className='p-0 border-0' >
                                        <NavLink to={`/dashboard`} className='border-0 rounded-0 px-2 py-2 d-block w-100'>
                                            <MDBIcon fas icon="tachometer-alt" className='me-3 menu-icon' />
                                            <span className='menuTitle mini-label'>Dashboard</span>
                                        </NavLink>
                                    </MDBListGroupItem>
                                </MDBRipple>
                                <MDBRipple rippleTag='li' className='my-2'>
                                    <MDBListGroupItem tag='div' action className='p-0 border-0' >
                                        <NavLink to={`/comments`} className='border-0 rounded-0 px-2 py-2 d-block w-100'>
                                            <MDBIcon fas icon='comment-dots' className='me-3 menu-icon' />
                                            <span className='menuTitle mini-label'>Comments</span>
                                        </NavLink>
                                    </MDBListGroupItem>
                                </MDBRipple>
                            </MDBListGroup>
                        </div>
                        {/* <div className='menu-footer border-top'>
                            <MDBListGroup flush="true" className="rounded-0 ">
                                <MDBRipple rippleTag='li' className='col p-0'>
                                    <MDBListGroupItem tag='div' href='/about' action className='border-0 rounded-0 border-end p-0 text-center'>
                                        <Link to='/about' className='rounded-0'>
                                            <span className="material-symbols-outlined" style={{
                                                transform: 'translateY(6px)'
                                            }}>
                                                info
                                            </span>
                                            <span className='no-mini-label'>About</span>
                                        </Link>
                                    </MDBListGroupItem>
                                </MDBRipple>
                                <MDBRipple rippleTag='li' className='col p-0'>
                                    <MDBListGroupItem tag='div' href='/help' action className='border-0 rounded-0 p-0 text-center'>
                                        <Link to='/help' className='rounded-0 '>
                                            <span className="material-symbols-outlined" style={{
                                                transform: 'translateY(6px)'
                                            }}>
                                                help
                                            </span>
                                            <span className='no-mini-label'>Help</span>
                                        </Link>
                                    </MDBListGroupItem>
                                </MDBRipple>
                            </MDBListGroup>
                        </div> */}
                    </div>

                </div>
            </MDBCollapse>

        </>
    )
}

export default LeftNav
