import React, { useEffect, useState } from 'react';
import './AppLayout.css'
import Topbar from '../topBar/Topbar';
import LeftNav from '../leftNav/LeftNav';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { selectMiniLeftNav } from '../../../redux/appSlice';
import { Navigate, Outlet, Route, Routes, Redirect, useNavigate } from 'react-router-dom'
import WildComponent from '../../../modules/common/wildComponent/WildComponent';
import Dashboard from '../../../modules/app/dashboard/Dashboard';
import ChangePwd from '../../../modules/app/changePwd/ChangePwd';
import Logout from '../../../modules/app/logout/Logout';
import Comments from '../../../modules/app/comments/Comments';
import apis from '../../../services/apis';
import { logout, selectToken } from '../../../redux/authSlice';

export default function AppLayout() {
    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const token$ = useSelector(selectToken);
    const [checAuth, setCheckAuth] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(checAuth > 0){            
            validateLogin();
        }
        setTimeout(() => {
            setCheckAuth(checAuth + 1);
        }, 1000*29);
    }, [checAuth]);
    const validateLogin = () => {
        console.log(token$);
        apis.setToken(token$).checkLogin().then((res) => {
            if (res.isValid) {
                apis.setToken(token$).getComments().then((res) => {
                    console.log("refreshtoken:",res);
                }).catch((error) => {
                    dispatch(logout());
                    navigate('/login');
                })
            }else{
                dispatch(logout());
                navigate('/login');
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
            <div className='app-container'>
                <Topbar />
                <LeftNav />
                <div className='app-body' style={{
                    transition: 'all 0.3s ease-in-out',
                    marginLeft: (isMiniLeftnav) ? '80px' : '250px',
                    marginRight: '10px',
                }} >
                    <MDBCard style={{
                        padding: '10px',
                        height: 'calc(100vh - 60px)',
                    }}>
                        <MDBCardBody className='p-0'>
                            <Routes>
                                <Route path='/'>
                                    <Route index element={<Navigate to='/dashboard' />} />
                                    <Route path='dashboard' element={<Dashboard />} />
                                    <Route path='comments' element={<Comments />} />
                                    <Route path='change-password' element={<ChangePwd />} />
                                    <Route path='logout' element={<Logout />} />
                                    
                                    <Route path="help" element={<WildComponent />} />
                                    {/* <Route element={<Navigate to='/dashboard' replace={true} />} /> */}
                                    <Route path="*" element={<Navigate to="/dashboard" />} />
                                </Route>
                            </Routes>
                            <Outlet />
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
        </>
    );
}
