import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectTokenData } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config'
import Iframe from 'react-iframe'
import { MDBBtn, MDBInput, MDBSpinner } from 'mdb-react-ui-kit';
const ChangePwd = () => {
    const userData = useSelector(selectTokenData);
    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [validations, setValidations] = useState({
        len: false,
        upper: false,
        lower: false,
        num: false,
        special: false,
        matchPwd: false
    });

    const handlePasswordChange = (password, flag) => {
        if (flag === 'confirm') {
            setConfirmPassword(password);
        } else {
            let len = password.length >= 6;
            let upper = password.match(/[A-Z]/);
            let lower = password.match(/[a-z]/);
            let num = password.match(/[0-9]/);
            let special = password.match(/[!@#$%^&*(),.?":{}|<>]/);
            let match = (flag === "confirm") ? false : password === confirmPassword;
            setValidations({
                len: len,
                upper: upper,
                lower: lower,
                num: num,
                special: special,
                matchPwd: match
            });
            // console.log('validations', validations);

            setNewPassword(password);
        }
    }

    const changePassword = () => {
        setIsLoading(true);
        if (newPassword !== confirmPassword) {
            setErrors({ password: "New Password and Confirm Password should be same" });
            setIsLoading(false);
            return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "PASSWORD": newPassword,
            "CONFIRM_PASSWORD": confirmPassword,
            "OBJID": userData.OBJID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${appConfig.apiBaseUrl}/auth/resetpwd`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result.statusCode >= 400) {
                    setErrors({ message: result.errMessage || "Failed to change password" });
                    setIsLoading(false)
                } else {
                    setIsLoading(false);
                    setErrors({});
                    localStorage.removeItem('ejdh_admin_state');
                    window.location.replace('/logout');
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <div className='row text-center'>
                <div className='col-3 mt-5 offset-3'>
                    <h4>Change Password</h4>
                    {Object.keys(errors).length > 0 && <div className="form-floating mb-3 text-danger">
                        {Object.values(errors).map((val, index) => {
                            return <p style={{
                                fontSize: "15px",
                                marginBottom: "5px",
                                lineHeight: "1rem"
                            }} key={index}>{val}</p>
                        })}
                    </div>}
                    <MDBInput
                        className='mb-3'
                        label='New Password'
                        onChange={(e) => { handlePasswordChange(e.target.value, 'new') }}
                        onKeyUp={(e) => { handlePasswordChange(e.target.value, 'new') }}
                        id='newPassword'
                        type='password' />
                    <MDBInput
                        className='mb-3'
                        label='Confirm Password'
                        onChange={(e) => { handlePasswordChange(e.target.value, 'confirm') }}
                        onKeyUp={(e) => { handlePasswordChange(e.target.value, 'confirm') }}
                        id='confirmPassword'
                        type='password' />
                    <div className=''>
                        <MDBBtn className='me-1' disabled={isLoading} onClick={changePassword}>
                            {isLoading && <MDBSpinner className='me-2' size='sm' role='status' tag='span' />}
                            Change Password
                        </MDBBtn>
                    </div>
                </div>
                <div className='col-3 mt-5 pt-3' style={{ textAlign: "left" }}>
                    <ul>
                        <li className={validations.len ? 'text-success' : 'text-danger'}>Must be at least 6 characters long</li>
                        <li className={validations.upper ? 'text-success' : 'text-danger'}>Must contain at least 1 uppercase letter</li>
                        <li className={validations.lower ? 'text-success' : 'text-danger'}>Must contain at least 1 lowercase letter</li>
                        <li className={validations.num ? 'text-success' : 'text-danger'}>Must contain at least 1 number</li>
                        <li className={validations.special ? 'text-success' : 'text-danger'}>Must contain at least 1 special character</li>
                        <li className={(newPassword.length && confirmPassword.length && newPassword === confirmPassword) ? 'text-success' : 'text-danger'}>
                            New Password and Confirm Password should be same
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ChangePwd
