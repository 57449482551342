import appConfig from "../configs/App.config";

class APIs {
    options = {};
    // constructor
    constructor() {
        this.options = {
            method: "POST",
            redirect: "follow",
            headers: new Headers()
        }
        this.options.headers.append("Content-Type", "application/json");
        this.options.headers.append('Access-Control-Allow-Origin', "*");
    }
    setToken(token) {
        if (!this.options.headers.has("Authorization")) {
            this.options.headers.append("Authorization", `Bearer ${token}`);
        }
        return this;
    }

    // login
    doLogin(username, password) {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/auth/login`,
            {
                ...this.options,
                body: JSON.stringify({
                    USER_NAME: username,
                    PASSWORD: password,
                    USER_TYPE: 'CBO-SUPER-USER'
                })
            }
        );
    }

    sendResetLink(email) {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/auth/forgotpwd`,
            {
                ...this.options,
                body: JSON.stringify({
                    EMAIL: email
                })
            }
        )
    }

    getCBOs(term = null) {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/admin/users/all`,
            {
                ...this.options,
                body: JSON.stringify({
                    TERM: term
                })
            }
        )
    }

    loginAsUser(userId) {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/auth/loginasuser/${userId}`,
            {
                ...this.options,
                method: "GET"
            }
        )
    }

    refreshToken() {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/auth/refreshtoken`,
            {
                ...this.options,
                method: "GET"
            }
        )
    }

    checkLogin() {
        return this.doFetch(
            `${appConfig.apiBaseUrl}/auth/checklogin`,
            {
                ...this.options,
                method: "GET"
            }
        )
    }

    getComments(){
        return this.doFetch(
            `${appConfig.apiBaseUrl}/comments/all`,
            {
                ...this.options,
                method: "GET"
            }
        ) 
    }

    readComment(id, status){
        return this.doFetch(
            `${appConfig.apiBaseUrl}/comments/${id}/setread/${status}`,
            {
                ...this.options,
                method: "PUT"
            }
        )
    }

    makeAllReasUnread(status){
        return this.doFetch(
            `${appConfig.apiBaseUrl}/comments/makeallread/${status}`,
            {
                ...this.options,
                method: "PUT"
            }
        )
    }

    doFetch(url, options) {
        return new Promise((resolve, reject) => {
            fetch(url, options)
                .then((res) => res.json())
                .then((res) => resolve(res))
                .catch((error) => reject(error));
        });
    }
}

export default APIs = new APIs();
