import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import appConfig from '../configs/App.config';
// import { createBrowserHistory } from "history";

// const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const browserHistory = createBrowserHistory({ basename: '' });
// Click Analytics configuration
const clickPluginConfig = {
    autoCapture: true
};
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: appConfig.instrumentationKey,
        connectionString: appConfig.connectionString,
        extensions: [reactPlugin, clickPluginInstance],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
            [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
// appInsights.loadAppInsights();
// appInsights.trackPageView();


export { reactPlugin, appInsights };